import React, { useState } from "react";
import { useNewInterventionForm } from "../../context/NewInterventionContext";
import { useStepValidator } from "../../hooks/useStepValidator";
import FieldPartNumber from "../fields/packaging/FieldPartNumber";
import FieldPackingInstructions from "../fields/packaging/FieldPackingInstructions";
import { Divider, useTheme } from "@material-ui/core";
import FieldAcceptPackaging from "../fields/packaging/FieldAcceptPackaging";
import PackingInstructionsAnimation from "../../animations/packingInstructionsRepturn.lottie";
import PartNumberExampleImage from "../../images/partNumberExampleRepturn.svg";
import { useTranslation } from "react-i18next";

interface StepPackagingErrors {
  packingInstructionsError?: string;
}

const StepPackaging: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { intervention } = useNewInterventionForm();
  const [errors, setErrors] = useState<StepPackagingErrors>({});

  useStepValidator(() => {
    const stepErrors: StepPackagingErrors = {
      packingInstructionsError: !intervention.packaging.instructions
        ? "RequiredFieldInstructionsError"
        : undefined,
    };
    setErrors(stepErrors);
    return !stepErrors.packingInstructionsError;
  });

  return (
    <>
      <FieldPartNumber
        exampleSrc={PartNumberExampleImage}
        txtInfo={t("PartNumberExample")}
      />
      <Divider
        style={{ marginTop: theme.spacing(5), marginBottom: theme.spacing(5) }}
      />
      <FieldPackingInstructions lottieSrc={PackingInstructionsAnimation} />
      <Divider
        style={{ marginTop: theme.spacing(5), marginBottom: theme.spacing(5) }}
      />
      <FieldAcceptPackaging
        error={errors.packingInstructionsError}
        onChange={() =>
          setErrors({
            ...errors,
            packingInstructionsError: undefined,
          })
        }
      />
    </>
  );
};

export default StepPackaging;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ContentTwoSided,
  LeftSide,
  RightSide,
} from "../../../../../components/core/ContentTwoSided";
import { useNewInterventionForm } from "../../../context/NewInterventionContext";
import { useStepValidator } from "../../../hooks/useStepValidator";
import FieldPartNumber from "../../fields/packaging/FieldPartNumber";
import FieldComplementaryPartInfos from "../../fields/packaging/FieldComplementaryPartInfos";
import FieldPackingInstructions from "../../fields/packaging/FieldPackingInstructions";
import FieldAcceptPackaging from "../../fields/packaging/FieldAcceptPackaging";
import { Divider, useTheme } from "@material-ui/core";
import PackingInstructionsAnimation from "../../../animations/packingInstructionsCotrolia.lottie";
import PartNumberExampleImage from "../../../images/partNumberExampleCotrolia.svg";

interface StepPackagingErrors {
  complementaryPartInfosError?: string;
  packingInstructionsError?: string;
}

const StepPackagingCotrolia: React.FC<{
  defaultComplementaryPartInfos?: string;
}> = (props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { intervention } = useNewInterventionForm();
  const [errors, setErrors] = useState<StepPackagingErrors>({});

  useStepValidator(() => {
    const stepErrors: StepPackagingErrors = {
      complementaryPartInfosError:
        !intervention.packaging.complementaryPart &&
        (!!intervention.packaging.complementaryPartInfos ||
          !!props.defaultComplementaryPartInfos)
          ? t("RequiredFieldComplementaryPartError")
          : undefined,
      packingInstructionsError: !intervention.packaging.instructions
        ? t("RequiredFieldInstructionsError")
        : undefined,
    };
    setErrors(stepErrors);
    return (
      !stepErrors.complementaryPartInfosError &&
      !stepErrors.packingInstructionsError
    );
  });

  return (
    <>
      <ContentTwoSided>
        <LeftSide>
          <FieldComplementaryPartInfos
            defaultInfos={props.defaultComplementaryPartInfos}
          />
        </LeftSide>
        <RightSide>
          <FieldPartNumber
            direction="column"
            exampleSrc={PartNumberExampleImage}
            txtInfo={t("PartNumberExampleCotrolia")}
          />
        </RightSide>
      </ContentTwoSided>
      <Divider
        style={{ marginTop: theme.spacing(5), marginBottom: theme.spacing(5) }}
      />

      <FieldPackingInstructions lottieSrc={PackingInstructionsAnimation} />
      <Divider
        style={{ marginTop: theme.spacing(5), marginBottom: theme.spacing(5) }}
      />
      <FieldAcceptPackaging
        error={
          errors.complementaryPartInfosError || errors.packingInstructionsError
        }
        onChange={() =>
          setErrors({
            ...errors,
            complementaryPartInfosError: undefined,
            packingInstructionsError: undefined,
          })
        }
        defaultInfos={props.defaultComplementaryPartInfos}
      />
    </>
  );
};

export default StepPackagingCotrolia;

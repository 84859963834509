import React, { useState } from "react";

import { Button, FormHelperText, useTheme } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { emptyAddress } from "../../../utils/model";
import CotroliaSelect from "./CotroliaSelect";
import CotroliaFormControl from "./CotroliaFormControl";
import CotroliaAutocomplete from "./CotroliaAutocomplete";
import CotroliaTextField from "./CotroliaTextField";
import { MenuItem } from "@material-ui/core";
import { useConfiguration } from "../../../context/configuration-context";
import CotroliaInputLabel from "./CotroliaInputLabel";
import { Address } from "../../../features/newintervention/types/NewInterventionFormTypes";

interface AddressSuggestion {
  properties: {
    name: string;
    postcode: string;
    city: string;
  };
}

const CotroliaAddress: React.FC<{
  label: string;
  onChange: (adresse: Address) => void;
  required?: boolean;
  defaultAddress?: Address;
  error: boolean;
  errorMsg?: string;
  disabled?: boolean;
}> = ({
  label,
  onChange,
  required = false,
  defaultAddress = emptyAddress,
  error,
  errorMsg,
  disabled = false,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [manual, setManual] = useState<boolean>(false);
  const [address, setAddress] = useState(defaultAddress);

  const prepareFullAddress = (address: Address) =>
    `${address.street}, ${address.zipCode} ${address.city}, ${address.country}`;

  const handleAddressChange = (value: Address) => {
    const newAddress = {
      ...value,
      fullAddress: prepareFullAddress(value),
    };
    setAddress(newAddress);
    onChange(newAddress); // Update parent address each change
  };

  const configuration = useConfiguration();

  const getSuggestions = (value: string) =>
    value?.length >= 3
      ? fetch("https://api-adresse.data.gouv.fr/search/?q=" + value)
          .then((res) => res.json())
          .then((res) =>
            res.features.map((item: AddressSuggestion) => {
              const opt = {
                ...emptyAddress,
                street: item.properties.name,
                city: item.properties.city,
                zipCode: item.properties.postcode,
                country: "France", // Country is fix with France since it's the france gouv api
                countryCode: "FR",
              };
              opt.fullAddress = prepareFullAddress(opt);
              return opt;
            }),
          )
          .catch((error) => {
            console.log(error);
            return [];
          })
      : [];

  return (
    <>
      <CotroliaFormControl error={error} style={{ margin: 0 }}>
        {!manual ? (
          <>
            <CotroliaAutocomplete
              id="address"
              label={label}
              value={address}
              onChange={(newValue: Address) => {
                handleAddressChange(newValue);
              }}
              getOptions={getSuggestions}
              required={required}
              error={error}
              helperText={!disabled ? t("addressHelperText") : ""}
              disabled={disabled}
              getOptionLabel={(option?: Address) => {
                return option?.fullAddress ?? "";
              }}
            />
          </>
        ) : (
          // if address not found output form address + zipcode + city + coutnry
          <>
            <CotroliaFormControl>
              <CotroliaTextField
                required={required}
                id="address"
                label={t("ShippingAddress")}
                value={address.street || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleAddressChange({ ...address, street: e.target.value });
                }}
                disabled={disabled}
                error={error && address.street === ""}
              />
            </CotroliaFormControl>
            <CotroliaFormControl>
              <CotroliaTextField
                required={required}
                id="zipcode"
                label={t("ZipCode")}
                value={address.zipCode || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleAddressChange({ ...address, zipCode: e.target.value });
                }}
                disabled={disabled}
                error={error && address.zipCode === ""}
              />
            </CotroliaFormControl>
            <CotroliaFormControl>
              <CotroliaTextField
                required={required}
                id="city"
                label={t("City")}
                value={address.city || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleAddressChange({ ...address, city: e.target.value });
                }}
                disabled={disabled}
                error={error && address.city === ""}
              />
            </CotroliaFormControl>
            <CotroliaFormControl variant="filled" required>
              <CotroliaInputLabel id="field-label">
                {t("CountryCode")}
              </CotroliaInputLabel>
              <CotroliaSelect
                id="country"
                labelId={t("CountryCode")}
                value={address.countryCode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleAddressChange({
                    ...address,
                    countryCode: e.target.value,
                    country: configuration.countries[e.target.value].label,
                  });
                }}
                disabled={disabled}
                error={error && address.countryCode === ""}
              >
                {Object.keys(configuration.countries).map((entry) => (
                  <MenuItem
                    key={configuration.countries[entry].code}
                    value={configuration.countries[entry].code}
                  >
                    {`${configuration.countries[entry].label} (${configuration.countries[entry].code})`}
                  </MenuItem>
                ))}
              </CotroliaSelect>
              <FormHelperText>
                {t("RestrictedShippingCountries")}
              </FormHelperText>
            </CotroliaFormControl>
          </>
        )}
        {!disabled && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setManual(!manual);
            }}
            style={{ alignSelf: "flex-start", marginTop: theme.spacing(3) }}
          >
            {manual ? t("addressAutoInput") : t("addressManualInput")}
          </Button>
        )}
        <FormHelperText variant="outlined">{errorMsg}</FormHelperText>
      </CotroliaFormControl>
    </>
  );
};

export default CotroliaAddress;
